<template>
  <div v-if="publishInfo">
    <div class="panel pb8">
      <div class="title-row">
        <PanelTitle title="任务类型"></PanelTitle>
        <RulePopover :rule="publishInfo.pubWarnTips"></RulePopover>
      </div>
      <div class="cate-row">
        <div class="wrap">
          <el-radio-group v-model="form.taskType" @input="radioInput">
            <el-radio :label="item.taskType" v-for="(item, index) in publishInfo.taskTypeRules" :key="index"
            :disabled="(act == 8 && form.taskType != item.taskType) ? true:false">{{item.name}}</el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
    <!-- 任务类型 -->
    <el-form ref="formRef" label-width="100px" :model="form" :rules="formRules" :show-message="false" >
      <div class="panel mt16">
        <div class="title-row">
          <PanelTitle title="填写基本信息"></PanelTitle>
        </div>
        <div class="content">
          <div class="wrap">
            <el-form-item label="项目名称：" prop="projectName">
              <el-autocomplete
                class="input-name"
                v-model="form.projectName"
                :fetch-suggestions="querySearch"
                placeholder="请输入项目名称"
                :trigger-on-focus="false"
                :disabled="act == 8">
                <template #append>
                  <div v-if="!taskId" class="btn-apply btn-fill-theme" @click="addProject">
                    <img src="@/assets/images/icon-file.png">
                    <span>申请该项目名</span>
                  </div>
                </template>
                <template slot-scope="{ item }">
                  <div class="search-row">
                    <div class="flex items-center">
                      <span>{{ item.value }}</span>
                      <span class="gray" v-if="item.projectStatusDesc">({{item.projectStatusDesc}})</span>
                    </div>
                    <span class="gray">{{ item.repeatDesc }}</span>
                  </div>
                </template>
              </el-autocomplete>
            </el-form-item>

            <el-form-item label="项目要求：" prop="projectRequire">
              <el-radio-group v-model="form.projectRequire">
                <el-radio :label="1">新用户</el-radio>
                <el-radio :label="2">不限新老</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="任务标题：" prop="taskTitle">
              <el-input v-model="form.taskTitle" type="text" placeholder="请输入标题"></el-input>
            </el-form-item>

            <el-form-item label="完成时间：" prop="duration">
              <el-select v-model="form.duration" placeholder="请选择">
                <el-option
                  v-for="item in durationOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="审核时间：" prop="auditDuration">
              <el-select v-model="form.auditDuration" placeholder="请选择">
                <el-option
                  v-for="item in durationOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <div class="tips-warn">
              <img src="@/assets/images/icon-warn.png">
              <span>超时未审核将自动通过，佣金发放，若多次超时未审核任务将被暂停，请及时审核</span>
            </div>

            <el-form-item label="做单设备：" prop="requirePlatform">
              <el-select v-model="form.requirePlatform" placeholder="请选择">
                <el-option
                  v-for="item in plateformOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="重复做单：" prop="repeat">
              <el-radio-group v-model="form.repeat">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="form.repeat" label="做单频次：" prop="repeat">
              <el-select class="mr8" v-model="form.repeatTimes" placeholder="请选择重复次数">
                <el-option
                  v-for="item in repeatTimeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              <el-select v-model="form.repeatDays" placeholder="请选择重复天数">
                <el-option
                  v-for="item in repeatDayOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item v-if="curTaskTypeRule" label="任务出价：" prop="taskPrice">
              <el-input v-model="form.taskPrice" type="number" :disabled="act == 8"
              :placeholder="curTaskTypeRule.leastPrice ? `${curTaskTypeRule.leastPrice}元起/单`:'请输入单价'">
                <template #suffix>
                  <div style="height: 40px">元</div>
                </template>
              </el-input>
            </el-form-item>

            <el-form-item v-if="curTaskTypeRule" label="任务数量：" prop="num">
              <el-input v-model="form.num" type="number" :disabled="act == 8 || form.taskType == 24"
              :placeholder="`最少${curTaskTypeRule.leastNum || 1}单`"></el-input>
            </el-form-item>

            <template v-if="curTaskTypeRule && curTaskTypeRule.needBail">
              <el-form-item label="保证金额：">
                <el-input :value="curTaskTypeRule.bailMin" disabled></el-input>
              </el-form-item>
              <div class="tips-warn">
                <img src="@/assets/images/icon-warn.png">
                <span>保证金额低于{{curTaskTypeRule.bailMin}}元时，按{{curTaskTypeRule.bailMin}}收取。最高收取{{curTaskTypeRule.bailMax}}元</span>
              </div>
            </template>


            <el-form-item label="预付合计：">
              <el-input :value="totalPrice" disabled>
                <template #suffix>
                  <div style="height: 40px">元</div>
                </template>
              </el-input>
            </el-form-item>

            <div class="up-vip">
              <img class="icon-warn" src="@/assets/images/icon-warn-yellow.png">
              <div>
                <div>降低服务费</div>
                <div class="mt6" v-if="memberBenefits">服务费：非会员{{memberBenefits[0].serveRate * 100}}%，普通VIP{{memberBenefits[1].serveRate * 100}}%，金牌VIP{{memberBenefits[2].serveRate * 100}}%。VIP所发布任务推荐/置顶，享受折扣。</div>
              </div>
              <div class="btn-upvip btn-fill-yellow" @click="$router.push('/user/userMember')">升级VIP</div>
            </div>

            <el-form-item label="任务说明：" prop="description">
              <el-input v-model="form.description" type="textarea" :rows="8" show-word-limit :maxlength="200" placeholder="请输入任务说明（最多200字）"></el-input>
            </el-form-item>
          </div>
        </div>
      </div>
      <!-- 多套任务 -->
      <div class="panel mt16 flex between-center" v-if="form.taskType == 24">
        <div class="flex items-center">
          <el-button type="primary" @click="addStepUnit">添加步骤单元</el-button>
          <el-button  v-if="form.stepUnits.length > 0" class="ml8" type="danger" @click="deleteStepUnit" :disabled="act == 8 && form.stepUnits[curStepUnitIndex].inventory != 0">删除</el-button>
        </div>
        <div class="flex items-center" v-if="form.stepUnits.length > 0">
          <el-input class="mr8" placeholder="请输入库存" type="number" v-model="form.stepUnits[curStepUnitIndex].inventory" :disabled="act == 8" @change="stepUnitInventoryChange"></el-input>
          <el-select v-model="curStepUnitIndex" placeholder="请选择" @change="stepUnitChange">
            <el-option
              v-for="(item,index) in form.stepUnits"
              :key="index"
              :label="`第${index + 1}套`"
              :value="index">
            </el-option>
          </el-select>
        </div>
      </div>

      <template v-if="form.taskType != 24 || (form.taskType == 24 && form.stepUnits.length > 0)">
        <!-- 设置任务步骤 -->
        <div class="panel mt16">
          <PanelTitle title="设置任务步骤"></PanelTitle>
        </div>
        <div v-for="(item, index) in form.steps" :key="item.key">

          <!-- 视频说明 -->
          <div class="panel mt8" v-if="item.stepType == 7">
            <StepOperate :title="`${index+1}、视频说明`" @change="handleStepOperate($event, index)"></StepOperate>
            <div class="content">
              <div class="wrap">
                <el-form-item label="视频说明" :prop="'steps.' + index + '.stepContent'" :rules="{required: true, trigger: 'blur'}">
                  <UploadVideo :auto-upload="false" @success="uploadImageSuccess($event, index, 'steps')"  :videoUrl="item.stepImg"/>
                </el-form-item>
                <el-form-item label="内容说明：" :prop="'steps.' + index + '.stepTitle'" :rules="{required: true, trigger: 'blur'}">
                  <el-input v-model="item.stepTitle" type="textarea" :rows="8" show-word-limit :maxlength="200" placeholder="请输入详细说明内容，引导任务的操作，必填！"></el-input>
                </el-form-item>
              </div>
            </div>
          </div>

          <!-- 图文说明 -->
          <div class="panel mt8" v-if="item.stepType == 0 || item.stepType == 6">
            <StepOperate :title="`${index+1}、${item.stepType == 6 ? '二维码':'图文说明'}`" @change="handleStepOperate($event, index)"></StepOperate>
            <div class="content">
              <div class="wrap">
                <el-form-item :label="item.stepType == 6 ? '二维码：': '添加图片：'" :prop="'steps.' + index + '.stepContent'" :rules="{required: true, trigger: 'blur'}">
                  <UploadImage :auto-upload="false" @success="uploadImageSuccess($event, index, 'steps')" :imgUrl="item.stepImg"/>
                </el-form-item>
                <el-form-item label="内容说明：" :prop="'steps.' + index + '.stepTitle'" :rules="{required: true, trigger: 'blur'}">
                  <el-input v-model="item.stepTitle" type="textarea" :rows="8" show-word-limit :maxlength="200" placeholder="请输入详细说明内容，引导任务的操作，必填！"></el-input>
                </el-form-item>
              </div>
            </div>
          </div>
          <!--网站链接  -->
          <div class="panel mt8" v-if="item.stepType == 3">
            <StepOperate :title="`${index+1}、网站链接`" @change="handleStepOperate($event, index)"></StepOperate>
            <div class="content">
              <div class="wrap">
                <el-form-item label="网址说明：" :prop="'steps.' + index + '.stepTitle'" :rules="{required: true, trigger: 'blur'}">
                  <el-input v-model="item.stepTitle" type="textarea" :rows="8" show-word-limit :maxlength="200" placeholder="请输入网址相关说明"></el-input>
                </el-form-item>
                <el-form-item label="填写网址：" :prop="'steps.' + index + '.stepContent'" :rules="{required: true, trigger: 'blur'}">
                  <el-input v-model="item.stepContent" type="text" placeholder="请输入网址，如http(s)://开头，必填！"></el-input>
                </el-form-item>
              </div>
            </div>
          </div>
          <!-- 复制数据 -->
          <div class="panel mt8" v-if="item.stepType == 5">
            <StepOperate :title="`${index+1}、复制数据`" @change="handleStepOperate($event, index)"></StepOperate>
            <div class="content">
              <div class="wrap">
                <el-form-item label="内容说明：" :prop="'steps.' + index + '.stepTitle'" :rules="{required: true, trigger: 'blur'}">
                  <el-input v-model="item.stepTitle" type="textarea" :rows="8" show-word-limit :maxlength="250" placeholder="请输入对复制内容的相关描述"></el-input>
                </el-form-item>
                <el-form-item label="复制内容：" :prop="'steps.' + index + '.stepContent'" :rules="{required: true, trigger: 'blur'}">
                  <el-input v-model="item.stepContent" type="textarea" :rows="8" show-word-limit :maxlength="250" placeholder="请输入让做任务的用户复制的内容，必填！"></el-input>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>

        <!-- 添加步骤 -->
        <div class="panel step-add">
          <el-dropdown>
            <div class="btn-step btn-fill-theme">
              <img src="@/assets/images/icon-listplus.png">
              <span>添加步骤</span>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <div class="drop-item" @click="addStep(0)">图文说明</div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div class="drop-item" @click="addStep(3)">网站链接</div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div class="drop-item" @click="addStep(5)">复制数据</div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div class="drop-item" @click="addStep(6)">二维码</div>
              </el-dropdown-item>
              <el-dropdown-item v-if="form.taskType == 24">
                <div class="drop-item" @click="addStep(7)">视频说明</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <!-- 设置提交 -->
        <div class="panel mt16">
          <PanelTitle title="设置提交任务必填项"></PanelTitle>
        </div>
        <div v-for="(item, index) in form.verifySteps" :key="item.key">
          <!-- 收集截图 -->
          <div class="panel mt8" v-if="item.stepType == 1">
            <StepOperate :title="`${index+1}、收集截图`" @change="handleVerifyStepOperate($event, index)"></StepOperate>
            <div class="content">
              <div class="wrap">
                <el-form-item label="添加图片：" :prop="'verifySteps.' + index + '.stepContent'" :rules="{required: true, trigger: 'blur'}">
                  <UploadImage :auto-upload="false" @success="uploadImageSuccess($event, index, 'verifySteps')" :imgUrl="item.stepImg"/>
                </el-form-item>
                <el-form-item label="截图说明：" :prop="'verifySteps.' + index + '.stepTitle'" :rules="{required: true, trigger: 'blur'}">
                  <el-input v-model="item.stepTitle" type="textarea" :rows="8" show-word-limit :maxlength="200" placeholder="详细说明需要截取的界面，需要符合什么要求，并上传实例图片，必填！"></el-input>
                </el-form-item>
              </div>
            </div>
          </div>
          <!-- 收集信息 -->
          <div class="panel mt8" v-if="item.stepType == 2">
            <StepOperate :title="`${index+1}、收集信息`" @change="handleVerifyStepOperate($event, index)"></StepOperate>
            <div class="content">
              <div class="wrap">
                <el-form-item label="信息说明：" :prop="'verifySteps.' + index + '.stepTitle'" :rules="{required: true, trigger: 'blur'}">
                  <el-input v-model="item.stepTitle" type="textarea" :rows="8" show-word-limit :maxlength="250" placeholder="可以添加信息步骤的描述，默认为：收集信息"></el-input>
                </el-form-item>
                <el-form-item label="信息类型：" :prop="'verifySteps.' + index + '.stepContent'" :rules="{required: true, trigger: 'blur'}">
                  <el-input v-model="item.stepContent" type="textarea" :rows="8" show-word-limit :maxlength="250" placeholder="收集何种信息，如：手机号前三后四，必填！"></el-input>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
        <div class="panel step-add">
          <el-dropdown>
            <div class="btn-step btn-fill-theme">
              <img src="@/assets/images/icon-listplus.png">
              <span>添加步骤</span>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <div class="drop-item" @click="addVerifyStep(1)">收集截图</div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div class="drop-item" @click="addVerifyStep(2)">收集信息</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </template>
    </el-form>

    <!-- 提示 -->
    <div class="pub-tips">
      <div>{{publishInfo.pubWarnTips}}</div>
    </div>

    <!-- 按钮组 -->
    <div class="bottom-group">
      <div class="btn-back" @click="$router.go(-1)">返回</div>
      <div class="btn-preview btn-fill-blue" v-if="form.taskType != 24" @click="publishOrPreview(true)">预览</div>
      <div class="btn-publish btn-fill-theme" v-if="!taskId" @click="publishOrPreview">申请发布</div>
      <div class="btn-publish btn-fill-theme" v-if="taskId && act==8" @click="modifyPublish">申请发布修改</div>
      <div class="btn-publish btn-fill-theme" v-if="taskId && (act==15 || act == 12)" @click="publishOrPreview">申请发布修改</div>
    </div>
    <!-- 预览 -->
    <Preview ref="previewPopupRef"/>
  </div>
</template>

<script>
import PanelTitle from '@/components/PanelTitle'
import RulePopover from '@/components/RulePopover'
import UploadImage from '@/components/UploadImage'
import UploadVideo from '@/components/UploadVideo'
import StepOperate from './components/StepOperate.vue'
import Preview from './components/Preview.vue'
import { queryPublishApi } from '@/api/merchant.js'
import { addProjectApi, searchProjectsApi, publishApi, taskDetailApi, taskManageApi } from '@/api/task.js'
import { memberBenefitApi } from '@/api/app.js'
import uploadImageUtil from '@/utils/uploadImageUtil.js'
export default {
  name: "PublishTask",
  components: {
    PanelTitle,
    RulePopover,
    UploadImage,
    StepOperate,
    Preview,
    UploadVideo
  },
  data(){
    return {
      taskId: '', // 修改任务id
      act: '', // 8已上架任务 12重新上架 15未上架任务
      form: {
        taskType: 0,
        projectName: '',
        projectRequire: 2,
        taskTitle: '',
        duration: '',
        auditDuration: '',
        requirePlatform: '',
        repeat: 0, // 0不可重复 1可重复
        repeatTimes: 0, // repeat为1 有效 重复次数
        repeatDays: 0, // repeat为1 有效 重复天数
        taskPrice: '',
        num: '',
        description: '',
        steps: [],
        verifySteps: [],
        promotionType: 0, //活动类型 0-无活动 1-免服务费
        stepUnits: []
      },
      curStepUnitIndex: 0, // 当前编辑的是第几套步骤
      durationOptions: [{label:'1小时',value:1},{label:'3小时',value:3},{label:'6小时',value:6},
      {label:'1天',value:24},{label:'3天',value:72},{label:'5天',value:120},{label:'7天',value:168}],
      plateformOptions: [{label:'不限', value: ''},{label:'安卓', value: 'android'},{label:'苹果', value: 'ios'}],
      repeatTimeOptions: [{label:'不限次', value: 0}, {label:'每天1次', value: 1},{label:'每天2次', value: 2},{label:'每天3次', value: 3},
      {label:'每天4次', value: 4},{label:'每天5次', value: 5},{label:'每天6次', value: 6}, {label:'每天7次', value: 7}],
      repeatDayOptions: [{label:'不限天', value: 0}, {label:'重复1天', value: 1},{label:'重复2天', value: 2},{label:'重复3天', value: 3},
      {label:'重复4天', value: 4},{label:'重复5天', value: 5},{label:'重复6天', value: 6}, {label:'重复7天', value: 7}],
      formRules: {
        projectName: [{ required: true, message: '', trigger: 'blur' }],
        projectRequire: [{ required: true, message: '', trigger: 'blur' }],
        taskTitle:  [{ required: true, message: '', trigger: 'blur' }],
        duration:  [{ required: true, message: '', trigger: 'blur' }],
        auditDuration:  [{ required: true, message: '', trigger: 'blur' }],
        requirePlatform:  [{ required: true, message: '', trigger: 'blur' }],
        repeat:  [{ required: true, message: '', trigger: 'blur' }],
        taskPrice:  [{ required: true, message: '', trigger: 'blur' }],
        num:  [{ required: true, message: '', trigger: 'blur' }],
        description:  [{ required: true, message: '', trigger: 'blur' }],
      },
      publishInfo: null,
      memberBenefits: null, //会员权益信息
    }
  },
  computed: {
    curTaskTypeRule() {
      const rlist = this.publishInfo.taskTypeRules
      if(rlist && rlist.length != 0) {
        return rlist.find(item => item.taskType == this.form.taskType)
      }
    },
    totalPrice() {
      const serveRate = this.publishInfo.serveRate
      const minServe = this.publishInfo.minServe
      if(serveRate * this.form.num * this.form.taskPrice > minServe) {
        return (this.form.num * this.form.taskPrice * ( 1 + serveRate)).toFixed(2)
      } else {
        return (this.form.num * this.form.taskPrice + minServe).toFixed(2)
      }
    }
  },
  created() {
    console.log('------this.$route.query----', this.$route.query)
    this.taskId = this.$route.query.taskId || ''
    this.act = this.$route.query.act || ''
    this.getPublishInfo()
    this.getTaskDetail()
    this.getBenefitInfo()
  },
  methods: {
    getPublishInfo() {
      queryPublishApi().then(res => {
        console.log('发布信息', res)
        this.publishInfo = res.data
        this.form.promotionType = res.data.promotionType || 0
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    querySearch(queryString, cb) {
      if(!queryString.trim()) return cb([])
      searchProjectsApi({
        'scene': 0,
				'searchWord': queryString
      }).then(res => {
        if(!res.data.projects || res.data.projects.length == 0) return cb([])
        const list = res.data.projects.map(item => ({
          value: item.projectName,
          repeatDesc: item.repeatDesc,
          projectStatusDesc: item.projectStatusDesc
        }))
        cb(list)
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    getTaskDetail() {
      if(!this.taskId) return
      taskDetailApi({
        'taskId': this.taskId,
				'source': 3
      }).then(res => {
        console.log('任务详情', res)
        if(!res.data) return
        this.form.taskType = res.data.taskType
        this.form.projectName = res.data.projectName || ''
        this.form.projectRequire = res.data.projectRequireCrow || 2
        this.form.taskTitle = res.data.title || ''
        this.form.duration = res.data.duration ? res.data.duration % 3600 == 0 ? res.data.duration/3600 : '' : ''
        this.form.auditDuration = res.data.auditDuration ? res.data.auditDuration % 3600 == 0 ? res.data.auditDuration/3600 :'' : ''
        this.form.requirePlatform = res.data.devicePlatform || ''
        this.form.repeat = res.data.repeat || 0
        this.form.repeatTimes = res.data.repeatTimes || 0
        this.form.repeatDays = res.data.repeatDays || 0
        this.form.taskPrice = Number(res.data.price) || ''
        this.form.num = Number(res.data.inventory) || ''
        this.form.description = res.data.description || ''

        if(this.form.taskType != 24) {
          if(res.data.steps?.length > 0) {
            this.form.steps = res.data.steps.map(item => {
              return {
                key: (parseInt(Math.random()*10000) + Date.now()).toString(),
                stepContent: item.stepContent,
                stepTitle: item.stepTitle,
                stepType: item.stepType,
                stepId: item.stepId,
                stepImg: item.stepContent,
              }
            })
          }
          // this.form.steps = res.data.steps || []
          // this.form.verifySteps = res.data.verifySteps || []
          if(res.data.verifySteps?.length > 0) {
            this.form.verifySteps = res.data.verifySteps.map(item => {
              return {
                key: (parseInt(Math.random()*10000) + Date.now()).toString(),
                stepContent: item.stepContent,
                stepTitle: item.stepTitle,
                stepType: item.stepType,
                stepId: item.stepId,
                stepImg: item.stepContent,
              }
            })
          }
        }
        if(this.form.taskType == 24 && res.data.stepUnits?.length > 0){
          // 多套步骤
          this.form.stepUnits = res.data.stepUnits
          for (const unitItem of this.form.stepUnits) {
            if(unitItem.steps?.length > 0) {
              unitItem.steps = unitItem.steps.map(item => {
                return {
                  key: (parseInt(Math.random()*10000) + Date.now()).toString(),
                  stepContent: item.stepContent,
                  stepTitle: item.stepTitle,
                  stepType: item.stepType,
                  stepId: item.stepId,
                  stepImg: item.stepContent,
                }
              })
            }
            if(unitItem.verifySteps?.length > 0) {
              unitItem.verifySteps = unitItem.verifySteps.map(item => {
                return {
                  key: (parseInt(Math.random()*10000) + Date.now()).toString(),
                  stepContent: item.stepContent,
                  stepTitle: item.stepTitle,
                  stepType: item.stepType,
                  stepId: item.stepId,
                  stepImg: item.stepContent,
                }
              })
            }
          }
          this.form.steps = this.form.stepUnits[0].steps
          this.form.verifySteps = this.form.stepUnits[0].verifySteps
        }

      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    addStep(stepType) {
      this.form.steps.push({
        key: (parseInt(Math.random()*10000) + Date.now()).toString(),
        stepContent: '',
        stepTitle: '',
        stepType,
        stepImg: '', // 上传图片时的 http地址url
      })
    },
    addVerifyStep(type) {
      this.form.verifySteps.push({
        key: (parseInt(Math.random()*10000) + Date.now()).toString(),
        stepContent: '',
        stepTitle: '',
        stepType: type,
        stepImg: ''
      })
    },
    handleStepOperate(type, index) {
      if(type == 'up') {
        if(index == 0) return
        const target = this.form.steps.splice(index, 1)
        this.form.steps.splice(index - 1, 0, ...target)
      }
      if(type == 'down') {
        if(index == this.form.steps.length - 1) return
        const target = this.form.steps.splice(index, 1)
        this.form.steps.splice(index + 1, 0, ...target)
      }
      if(type == 'delete') {
        this.$confirm('确定删除此步骤吗').then(action => {
          if(action == 'confirm') {
            this.form.steps.splice(index, 1)
          }
        }).catch(err => {})
      }
    },
    handleVerifyStepOperate(type, index) {
      if(type == 'up') {
        if(index == 0) return
        const target = this.form.verifySteps.splice(index, 1)
        this.form.verifySteps.splice(index - 1, 0, ...target)
      }
      if(type == 'down') {
        if(index == this.form.verifySteps.length - 1) return
        const target = this.form.verifySteps.splice(index, 1)
        this.form.verifySteps.splice(index + 1, 0, ...target)
      }
      if(type == 'delete') {
        this.$confirm('确定删除此步骤吗').then(action => {
          if(action == 'confirm') {
            this.form.verifySteps.splice(index, 1)
          }
        }).catch(err => {})
      }
    },
    addProject() {
      if(!this.form.projectName.trim()) return this.$tips({message: '项目名称不能为空', type: 'warning'})
      this.$confirm('确认申请该项目名？需要经过平台审核，请耐心等待！').then(action => {
        if(action == 'confirm') {
          addProjectApi({
            'act': 1,
            'projectDesc': '',
            'projectName': this.form.projectName,
            'repeatable': ''
          }).then(res => {
            this.$tips({message: res.msg, type: 'success'})
          }).catch(err => {
            this.$tips({message: '操作失败', type: 'error'})
          })
        }
      }).catch(err => {})
    },
    uploadImageSuccess(e, index, type) {
      if(type == 'steps') {
        const target = this.form.steps[index]
        Object.assign(target, {
          stepImg: e.base64Url,
          file: e.file
        })
      }
      if(type == 'verifySteps') {
        const target = this.form.verifySteps[index]
        Object.assign(target, {
          stepImg: e.base64Url,
          file: e.file
        })
      }
    },
    async publishOrPreview(isPreview) {
      console.log('发布', this.form, this.act)
      if(!this.curTaskTypeRule) return this.$tips({message: '请选择任务类型', type: 'warning'})
      if(!this.form.projectName.trim()) return this.$tips({message: '项目名称不能为空', type: 'warning'})
      if(!this.form.taskTitle.trim()) return this.$tips({message: '任务标题不能为空', type: 'warning'})
      if(this.form.duration === '') return this.$tips({message: '请选择任务完成时间', type: 'warning'})
      if(this.form.auditDuration === '') return this.$tips({message: '请选择任务审核时间', type: 'warning'})
      if(!this.form.taskPrice || this.form.taskPrice == 0) return this.$tips({message: '任务出价不能为空', type: 'warning'})
      if(this.form.num < this.curTaskTypeRule.leastNum) return this.$tips({message: `任务数量不能少于${this.curTaskTypeRule.leastNum}`, type: 'warning'})
      if(!this.form.description.trim()) return this.$tips({message: '任务说明不能为空', type: 'warning'})
      if(this.form.steps.length == 0) return this.$tips({message: '任务步骤不能为空', type: 'warning'})
      if(this.form.verifySteps.length == 0) return this.$tips({message: '提交任务必填项不能为空', type: 'warning'})

      const formData = {
        publishType: this.act == 15 ? 1 : 0, // 0-新创建发布 & 1-未上架任务修改发布，taskId必传; 已上架任务使用manageApi
        totalPrice: this.totalPrice,
        taskType: this.form.taskType,
        projectName: this.form.projectName,
        projectRequire: this.form.projectRequire,
        taskTitle: this.form.taskTitle,
        duration: this.form.duration,
        auditDuration: this.form.auditDuration,
        requirePlatform: this.form.requirePlatform,
        repeat: this.form.repeat,
        repeatTimes: this.form.repeatTimes,
        repeatDays: this.form.repeatDays,
        taskPrice: this.form.taskPrice,
        num: this.form.num,
        description: this.form.description,
        promotionType: this.form.promotionType,
      }
      if(this.form.taskType != 24) {
        try {
          const steps = await this.handleStepsUpload({steps: this.form.steps})
          const verifySteps = await this.handleStepsUpload({steps: this.form.verifySteps, isVerify: true})
          Object.assign(formData, {
            steps,
            verifySteps
          })
        } catch (err) {
          return this.$tips({message: err.message, type: 'warning'})
        }

      } else {
        const stepUnits = []
        for (const item of this.form.stepUnits) {
          if(!item.inventory) return this.$tips({message: '步骤库存不能小于0', type: 'warning'})
          const obj = {
            steps: [],
            verifySteps: [],
            inventory: isNaN(parseInt(item.inventory)) ? 0 : parseInt(item.inventory)
          }
          if(item.mid) Object.assign(obj, {mid: item.mid})
          try {
            obj.steps = await this.handleStepsUpload({steps: item.steps})
            obj.verifySteps = await this.handleStepsUpload({steps: item.verifySteps, isVerify: true})
            stepUnits.push(obj)
          } catch (err) {
            return this.$tips({message: err.message, type: 'warning'})
          }
        }
        Object.assign(formData, {
          stepUnits
        })
      }
      if(isPreview === true) {
        // 预览
        this.$refs.previewPopupRef.open({
          detail: this.form,
          publishInfo: this.publishInfo
        })
      } else {
        // 修改未上架任务
        if(this.taskId) Object.assign(formData, {
          'taskId': this.taskId
        })
        console.log('formData', formData)
        publishApi(formData).then(res => {
          console.log('发布任务', res)
          this.$tips({message: '发布任务成功', type: 'success'})
          const timer = setTimeout(() => {
            this.$router.replace('/publishManage/list')
            clearTimeout(timer)
          }, 800)
        }).catch(err => {
          this.$tips({message: err, type: 'error'})
        })
      }

    },
    handleStepsUpload({steps, isVerify}) {
      const resList = []
      const imgStepType = isVerify ? [1] : [0,6,7]
      return new Promise(async (resolve, reject) => {
        for (const item of steps) {
          const obj = {
            stepTitle: item.stepTitle,
            stepType: item.stepType,
            stepContent: item.stepContent
          }
          if(item.stepId) Object.assign(obj, {stepId: item.stepId})
          if(imgStepType.includes(item.stepType)) {
            if(item.file) {
              try {
                const { uploadImageAsync } = await uploadImageUtil()
                const res = await uploadImageAsync(item.file)
                obj.stepContent = res.ossUrl
              } catch (err) {
                console.log(err)
                reject(new Error( item.stepType === 7? '视频上传失败': '图片上传失败'))
              }
            }
          }
          if(!obj.stepContent.trim() || !obj.stepTitle.trim()) return reject(new Error('请检查任务步骤信息是否填写完整'))
          resList.push(obj)
        }
        resolve(resList)
      })
    },
    async modifyPublish() {
      // 已上架修改
      if(!this.curTaskTypeRule) return this.$tips({message: '请选择任务类型', type: 'warning'})
      if(!this.form.projectName.trim()) return this.$tips({message: '项目名称不能为空', type: 'warning'})
      if(!this.form.taskTitle.trim()) return this.$tips({message: '任务标题不能为空', type: 'warning'})
      if(this.form.duration === '') return this.$tips({message: '请选择任务完成时间', type: 'warning'})
      if(this.form.auditDuration === '') return this.$tips({message: '请选择任务审核时间', type: 'warning'})
      if(!this.form.taskPrice || this.form.taskPrice == 0) return this.$tips({message: '任务出价不能为空', type: 'warning'})
      if(!this.form.num) return this.$tips({message: '任务数量不能为空', type: 'warning'})
      if(!this.form.description.trim()) return this.$tips({message: '任务说明不能为空', type: 'warning'})
      if(this.form.steps.length == 0) return this.$tips({message: '任务步骤不能为空', type: 'warning'})
      if(this.form.verifySteps.length == 0) return this.$tips({message: '提交任务必填项不能为空', type: 'warning'})
      const formData = {
        'type': 8,
        'taskId': this.taskId,
        'extParam': {
          'auditDuration': this.form.auditDuration,
          'description': this.form.description,
          'platform': this.form.requirePlatform || 'all',
          'repeat': this.form.repeat,
          'repeatDays': this.form.repeatDays,
          'repeatTimes': this.form.repeatTimes,
          'taskDuration': this.form.duration,
          'projectRequire': this.form.projectRequire,
          'taskTitle': this.form.taskTitle,
        }
      }
      if(this.form.taskType != 24) {
        try {
          const changeSteps = await this.handleStepsUpload({steps: this.form.steps})
          const changeVerifySteps = await this.handleStepsUpload({steps: this.form.verifySteps, isVerify: true})
          Object.assign(formData.extParam, {
            'changeSteps': JSON.stringify(changeSteps),
            'changeVerifySteps': JSON.stringify(changeVerifySteps),
          })
        } catch (err) {
          return this.$tips({message: err.message, type: 'warning'})
        }
      } else {
        const changeStepUnits = []
        for (const item of this.form.stepUnits) {
          const obj = {
            steps: [],
            verifySteps: [],
            inventory: isNaN(parseInt(item.inventory)) ? 0 : parseInt(item.inventory)
          }
          if(item.mid) Object.assign(obj, {mid: item.mid})
          try {
            obj.steps = await this.handleStepsUpload({steps: item.steps})
            obj.verifySteps = await this.handleStepsUpload({steps: item.verifySteps, isVerify: true})
            changeStepUnits.push(obj)
          } catch (err) {
             return this.$tips({message: err.message, type: 'warning'})
          }
        }
        Object.assign(formData.extParam, {
          'changeStepUnits': JSON.stringify(changeStepUnits)
        })
      }
      console.log('formData', formData)
      taskManageApi(formData).then(res => {
        console.log('任务修改', res)
        this.$tips({message: '发布任务成功', type: 'success'})
        const timer = setTimeout(() => {
          this.$router.replace('/publishManage/list')
          clearTimeout(timer)
        }, 800)
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    // 获取会员权益
    getBenefitInfo() {
      memberBenefitApi().then(res => {
        this.memberBenefits = res.data.memberBenefits
      }).catch((err) => {
        this.$tips({ message: err, type: "error" });
      })
    },
    addStepUnit() {
      const obj = {
        inventory: '',
        steps: [],
        verifySteps: []
      }
      if(this.form.stepUnits?.length > 0) {
        // obj.verifySteps = JSON.parse(JSON.stringify(this.form.stepUnits[0].verifySteps))
        // 多套步骤默认取第一次的验证步骤
        const firstVerifySteps = this.form.stepUnits[0].verifySteps
        console.log('复制verifySteps', this.form.stepUnits)
        if(firstVerifySteps?.length > 0) {
          for (const item of firstVerifySteps) {
            obj.verifySteps.push({
              key: (parseInt(Math.random()*10000) + Date.now()).toString(),
              stepContent: item.stepContent,
              stepTitle: item.stepTitle,
              stepType: item.stepType,
              stepImg: item.stepImg,
              file: item.file
            })
          }
        }
      }
      this.form.stepUnits.push(obj)
      this.form.steps = obj.steps
      this.form.verifySteps = obj.verifySteps
      this.curStepUnitIndex = this.form.stepUnits.length - 1
    },
    deleteStepUnit() {
      this.$confirm('确认删除该单元吗？').then(action => {
        if(action == 'confirm') {
          this.form.stepUnits.splice(this.curStepUnitIndex, 1)
          this.stepUnitChange(this.curStepUnitIndex - 1 < 0 ? 0 : this.curStepUnitIndex - 1)
        }
      }).catch(err => {})
    },
    stepUnitChange(e) {
      console.log(e)
      this.curStepUnitIndex = e
      const target = this.form.stepUnits[e]
      console.log('target', target)
      if(target) {
        this.form.steps = target.steps
        this.form.verifySteps = target.verifySteps
      }
    },
    stepUnitInventoryChange() {
      if(this.form.stepUnits.length <= 0) return
      this.form.num = this.form.stepUnits.reduce((total, item) => total + (isNaN(Number(item.inventory)) ? 0 : Number(item.inventory)), 0)
    },
    radioInput(e){
      console.log("选择任务类型：",e)
      // 如果选择的任务类型不是代发任务，设置任务步骤中不需要有上传视频的步骤；
      if(e != 24){
        this.form.steps = this.form.steps.filter(item =>item.stepType != 7)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.el-autocomplete {
  width: 100%;
}
::v-deep .el-upload--picture-card {
  background: #fff;
}

$contentWidth: 586px;
.title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px 16px;
  margin: 0 -24px;
  border-bottom: 1px solid #F3F3F3;

}

// 搜索项目名
.search-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  .gray {
    color: #B0B3BF;
  }
}

.cate-row {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  .wrap {
    width: $contentWidth;
  }
  ::v-deep .el-radio {
    margin-right: 25px;
    margin-bottom: 16px;
  }
}
.content {
  display: flex;
  justify-content: center;
  padding-top: 24px;
  .wrap {
    width: $contentWidth;
  }
}
.input-name {
  display: flex;
  align-items: center;
  background-color: #fff;
  ::v-deep .el-input-group__append {
    background-color: #fff;
    padding: 0;
  }
}
.btn-apply {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-radius: 0 4px 4px 0;
  width: 100%;
  height: 38px;
  padding: 0 16px;
  cursor: pointer;
  img {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
}
.tips-warn {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--theme-color-red);
  margin-top: 8px;
  margin-bottom: 20px;
  padding-left: 15px;
  img {
    width: 12px;
    height: 12px;
    margin-right: 6px;
  }
}
// 升级vip
.up-vip {
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  margin-bottom: 20px;
  font-size: 12px;
  color: var(--theme-color-yellow);
  .icon-warn {
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }
  .btn-upvip {
    height: 24px;
    line-height: 24px;
    border-radius: 12px;
    padding: 0 8px;
    font-size: 12px;
    cursor: pointer;
  }
}
.step-add {
  display: flex;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-top: 8px;
  .btn-step {
    display: flex;
    align-items: center;
    height: 42px;
    padding: 0 48px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    img {
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }
  }
}
.drop-item {
  width: 136px;
  height: 46px;
  line-height: 46px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  &:hover {
    color: var(--theme-color);
    background-color: #FFF2ED;
  }
}

.pub-tips {
  display: flex;
  justify-content: center;
  color: #99A3A3;
  font-size: 14px;
  margin-top: 24px;
  white-space: pre-line;
  line-height: 24px;
}

.bottom-group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  .btn-back, .btn-publish, .btn-preview {
    height: 42px;
    line-height: 42px;
    width: 150px;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
  }
  .btn-back {
    background-color: #fff;
    border: 1px solid #E8E8E8;
    margin-right: 20px;
  }
  .btn-preview {
    margin-right: 20px;
  }
}

</style>
